.body {
margin-top: 72px;
background-color: black;
height: 25vh;
min-height: 265px;
}

.desc {
    margin-top: 80px;
    padding-top: 24px;
    display: flex;
    justify-content: center;
    color: white;
}

.descText {
    width: 685px;
    font-size: 18px;
    font-weight: 700;
}


.selectBtnContainer {
    margin-top: 32px;
    display: flex;
    justify-content: center;
}

.selectBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    background-color: white;
    width: 120px;
    height: 48px;
    color: black;
    border-radius: 15px 0 0 15px;
    font-weight: 600;
}

.selectBtn3 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    background-color: white;
    width: 120px;
    height: 48px;
    color: black;
    font-weight: 600;
}

.outBtn {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 128px;
    height: 64px;
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    border-radius: 15px 0 0 15px;
}

.outBtn2 {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 56px;
    width: 128px;
    height: 64px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    border-radius: 0 15px 15px 0;
}

.outBtn3 {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 120px;
    height: 56px;
    height: 64px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}

.selectBtn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    background-color: white;
    width: 120px;
    height: 48px;
    color: black;
    border-radius: 0 15px 15px 0;
    font-weight: 600;
}

.selectBtn:hover {
    cursor: pointer;
    background-color: gray;
    transition: ease-in-out;
}

.selectBtn2:hover {
    cursor: pointer;
    background-color: gray;
    transition: ease-in-out;
}

.selectBtn3:hover {
    cursor: pointer;
    background-color: gray;
    transition: ease-in-out;
}

.content{
    margin-top: 64px;
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}