.body{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 70vh;
    min-height: 520px;
    min-width: 300px;
    flex-direction: column;
}

.body h1{
    color: white;
}

.body a{
    color: white;
}

.subText{
    width: 36vw;
}

.buttons{
    margin-top: 6vh;
    display: flex;
    width: 36vw;
    justify-content: space-evenly;
}

.portBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    height: 4.8vh;
    min-width: 120px;
    min-height: 48px;
    background-color: white;
}

.portBtn:hover{
    background-color: gray;
}

.portBtn a{
    color: black;
    font-size: 1.3em;
    font-weight: 600;
}

.outBox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10.6vw;
    height: 5.2vh;
    min-width: 128px;
    min-height: 56px;
    border: 2px solid white;
}


.contactBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    min-width: 120px;
    min-height: 48px;
    height: 4.8vh;
    background-color: white;
}

.contactBtn:hover{
    background-color: gray;
}

.contactBtn a{
    color: black;
    font-size: 1.3em;
    font-weight: 600;
}

.quip{
    padding-left: 4vw;
    width: 160px;
}

.intro{
    margin-top: 32px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.pic{
    width: 400px;
    height: 400px;
    min-width: 280px;
    min-height: 280px;
    border-radius: 50%;
    contain: content;
    background-image: url("../assets/SelfPortrait.PNG");
    background-position: center;
    background-size: 100%;
}

.technologyBox {
    width: 520px;
    height: 265px;
    background-color: black;
}

.techInfo {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    height: 320px;
}
