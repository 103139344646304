.card{
    display: flex;
    margin-top: 12px;
    width: 800px;
    min-width: 400px;
    height: 265px;
    background-color: Black;
    border: 2px solid black;
}

.cardImage{
min-width: 200px;
width: 500px;
max-width: 500px;
height: 265px;
object-fit: contain;
}

.info{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: white;
    justify-content: space-between;
    width: 195px;
}

.title{
    display: flex;
    font-size: 30px;
    justify-content: center;
}

.desc{
    font-size: 15px;
    padding-left: 2.5px;
    padding-right: 2.5px;
}

.buttons{
    display: flex;
    justify-content: space-evenly;
}

.navButton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    color: black;
    font-weight: 500;
    width: 88px;
    height: 28px;
    border: 1px solid black;
    background-color: white;
    border-radius: 8px;
}

.navButton a:link{
text-decoration: none;
color: black;
}

.navButton a:visited{
    color: black;
    text-decoration: none;
    }

.navButton:hover{
    cursor: pointer;
    background-color: gray;
}