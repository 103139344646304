.inputBoxes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 128px;
}

.normalInput{
    width: 320px;
    height: 38px;
    font-size: 24px;
    margin-top: 12px;
}

.messageInput{
    display: flex;
    margin-top: 12px;
    width: 320px;
    height: 290px;
    text-align: left;
    resize: none;
    font-size: 16px;
}

.submitBtn{
display: flex;
margin-top: 12px;
background-color: black;
width: 110px;
height: 40px;
border-radius: 16px;
color: white;
justify-content: center;
align-items: center;
font-weight: 600;
}

.submitBtn:hover{
    cursor: pointer;
    background-color: gray;
}